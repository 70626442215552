<template>
  <div class="">
    <nav id="header" class="w-full">
      <div class="flex break-words bg_dark_gray newSty_bg w-full">
        <div class="flex w-full">
          <div class="flex justify-between w-full py-0.5" name="expand">
            <a
              href="#"
              class="hamburger_icon cursor-pointer z-6 ml-2"
              @click="toggleLeftSideBar()"
            >
              <svg
                class="hamburgerTandD"
                xmlns="http://www.w3.org/2000/svg"
                :class="{ ActiveHamburgerTandD: show_left_bar }"
                width="20"
                height="15"
                viewBox="0 0 20 15"
                fill="none"
              >
                <path
                  d="M0 6.25H15V8.75H0V6.25ZM0 0H20V2.5H0V0ZM0 15H9.04375V12.5H0V15Z"
                  :fill="darkDark != 'white' ? '#fff' : '#000'"
                />
              </svg>
            </a>
            <div class="pr-5 flex" :id="'newFileSaveBtn'+widgetId">
              <!-- <div class="fontSize mx-2 flex items-center">
                <input
                  type="checkbox"
                  class="checkbox hidden"
                  :id="'selectType'+widgetId"
                  v-model="selectedType"
                />
                FLAT FILE
                <label
                  :for="'selectType'+widgetId"
                  class="toggleLabel checkbox-label td_checkbox_zoom mx-2"
                >
                  <span class="ball"></span>
                </label>
                WITSML
              </div> -->

              <div class="fontSize mx-2 flex items-center" style="gap: 10px;">
                <div class="flex items-center" v-for="(sourceName,index) of allSourceTypes" :key="index">
                  <input
                    type="radio"
                    :id="sourceName+widgetId"
                    :value="sourceName"
                    v-model="selectedType"
                  />
                  <label :for="sourceName+widgetId" class="pl-1"> {{ sourceName }}</label>
                </div>
              </div>

              <button
                class="mx-2 buttonStyles fontSize"
                @click="$emit('toggle_management')"
              >
                Management
              </button>
              <button
                class="mx-2 buttonStyles fontSize"
                @click="$emit('add_well_pop')"
              >
                Add Well
              </button>
              <!-- <p class="hidden">
                {{ srcWellLWDData }}
              </p> -->
              <button v-if="(selectedType == 'WITSML' || selectedType == 'WITS0') && srcWellLWDData['well_status'] != 6" class="mx-2 fontSize px-2 rounded uppercase" :class="srcWellLWDData['well_status'] != 4 && srcWellLWDData['well_status'] != 104 ? 'startButton' : 'bg-red-500'" @click="stopWellPop()">
                {{srcWellLWDData['well_status'] != 4 && srcWellLWDData['well_status'] != 104 ? 'Start' : 'Stop'}}
              </button>
              <!-- {{ srcWellLWDData }} -->
              <button
                v-if="(selectedType == 'WITSML' || selectedType == 'WITS0')"
                class="mx-2 button-depth text_xs newStylesLiveBtn"
                @click="$emit('make_it_live')"
                style="order:1;"
                :style="
                  !isBackgroundLive
                    ? 'color: #fff; background:var(--liveBtnGreen)'
                    : 'background:#b6adb4; color:#fff;'
                "
              >
                <div
                  class="liveBtnDot"
                  style="background:#fff"
                ></div>
                LIVE
              </button>
            </div>
            <div class="pr-5">
              <button @click="$emit('hide_zoom_tooltip')">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="nav_1_icon settingsIcon"
                  width="15"
                  height="15"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M17.1401 10.94C17.1801 10.64 17.2001 10.33 17.2001 10C17.2001 9.68003 17.1801 9.36002 17.1301 9.06002L19.1601 7.48002C19.3401 7.34002 19.3901 7.07002 19.2801 6.87002L17.3601 3.55002C17.2401 3.33002 16.9901 3.26002 16.7701 3.33002L14.3801 4.29002C13.8801 3.91002 13.3501 3.59002 12.7601 3.35002L12.4001 0.810024C12.3601 0.570024 12.1601 0.400024 11.9201 0.400024H8.08011C7.84011 0.400024 7.65011 0.570024 7.61011 0.810024L7.25011 3.35002C6.66011 3.59002 6.12011 3.92002 5.63011 4.29002L3.24011 3.33002C3.02011 3.25002 2.77011 3.33002 2.65011 3.55002L0.74011 6.87002C0.62011 7.08002 0.66011 7.34002 0.86011 7.48002L2.89011 9.06002C2.84011 9.36002 2.80011 9.69002 2.80011 10C2.80011 10.31 2.82011 10.64 2.87011 10.94L0.84011 12.52C0.66011 12.66 0.61011 12.93 0.72011 13.13L2.64011 16.45C2.76011 16.67 3.01011 16.74 3.23011 16.67L5.62011 15.71C6.12011 16.09 6.65011 16.41 7.24011 16.65L7.60011 19.19C7.65011 19.43 7.84011 19.6 8.08011 19.6H11.9201C12.1601 19.6 12.3601 19.43 12.3901 19.19L12.7501 16.65C13.3401 16.41 13.8801 16.09 14.3701 15.71L16.7601 16.67C16.9801 16.75 17.2301 16.67 17.3501 16.45L19.2701 13.13C19.3901 12.91 19.3401 12.66 19.1501 12.52L17.1401 10.94ZM10.0001 13.6C8.02011 13.6 6.40011 11.98 6.40011 10C6.40011 8.02002 8.02011 6.40002 10.0001 6.40002C11.9801 6.40002 13.6001 8.02002 13.6001 10C13.6001 11.98 11.9801 13.6 10.0001 13.6Z"
                    :fill="
                      hide_tooltip_zoom
                        ? darkDark != 'white'
                          ? '#fff'
                          : '#000'
                        : 'var(--activeTextColor)'
                    "
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>


    <!-- model -->

    <div class="fixed z-10 overflow-y-auto top-0 w-full left-0 model_height model_bg" v-if="Activate_LWD_POP">
    <div
      class="flex items-center justify-center min-height-100vh pt-3 px-4 pb-20 text-center sm:block sm:p-0 model_height"
    >
      <div class="fixed inset-0 transition-opacity">
        <!-- <div class="absolute inset-0 bg-gray-900 opacity-75" > -->
      </div>
      <div class="container mx-auto px-4 h-full tbl_mdl tbl_mdl_lwd_model">
        <div class="flex content-center items-center justify-center h-full">
          <div class="w-full px-4 h-full">
            <div
              class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg confirm_Dia_Box border-0"
            >
              <div class="rounded-t mb-0 px-6 py-6"></div>

              <section class="startStopSection">
                <div class="w-full max-w-xs">
                  <div class="flex items-center justify-between">
                    <button
                      class="text-red-500 hover:text-red-700 w-1/3 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline absolute top-0 right-0"
                      type="button"
                      @click="stopWellPop()"
                    >
                      <i class="fas fa-times"></i>
                    </button>
                  </div>

                  <div class="flex flex-wrap">

                    <div class="w-full mx-auto px-4">
                      <div class="relative flex flex-col min-w-0 break-words w-full mb-6 ">
                        <div class="flex-auto py-10 dark_background flex items-center">
                          <div class="container mx-auto fields flex items-center h-full justify-center">
                            <div class="">
                              <section class="flex fields" v-if="wellDetails.sourceType === 'WITSML' || wellDetails.sourceType === 'witsml'">
                                <div class="mr-1" v-if="this.srcWellLWDData.well_status !== 4 && this.srcWellLWDData.well_status !== 104">
                                  <label for="depthFreq" class="text-left">
                                      Choose LWD Depth Frequency
                                    </label> <br>
                                    <select name="" id="depthFreq" class="bgDark __selected_welldata fields" v-model="selected_wellType" @change="selectedWellData">
                                      <option :value="welldata" v-for="(welldata, w_id) of wellData.logList" :key="w_id" >
                                      {{ welldata.logname }}
                                      </option>
                                    </select>
                                </div>
                              <div class="ml-1" v-if="this.srcWellLWDData.well_status == 5 || this.srcWellLWDData.well_status == 6 || this.srcWellLWDData.well_status == 7 || this.srcWellLWDData.well_status == 8 || this.srcWellLWDData.well_status == 104">
                                  <label for="depthFreq" class="text-left">
                                      start from
                                    </label> <br>
                                    <select name="" id="depthFreq" class="bgDark __selected_welldata fields" v-model="runType" @change="chooseRunType">
                                      <option :value="runType" v-for="(runType, r_id) in runFrom" :key="r_id" >
                                      {{ runType }}
                                      </option>
                                    </select>
                                </div>
                              </section>
                              <section class="flex fields" v-if="wellDetails.sourceType === 'wits0' && this.srcWellLWDData.well_status !== 4 && this.srcWellLWDData.well_status !== 104" disabled>
                                <div class="mr-1" v-if="this.srcWellLWDData.well_status !== 4 && this.srcWellLWDData.well_status !== 104">
                                  <label for="depthFreq" class="text-left">
                                      Choose LWD Depth Frequency
                                    </label> <br>
                                    <select name="" id="depthFreq" class="bgDark __selected_welldata fields" v-model="selected_wellType" @change="selectedWellData">
                                      <option :value="welldata" v-for="(welldata, w_id) of wellData" :key="w_id" selected>
                                      {{ welldata.log + '_' + welldata.frequency }}
                                      </option>
                                    </select>
                                </div>
                              <div class="ml-1" v-if="this.srcWellLWDData.well_status == 5 || this.srcWellLWDData.well_status == 6 || this.srcWellLWDData.well_status == 7 || this.srcWellLWDData.well_status == 8 || this.srcWellLWDData.well_status == 104">
                                  <label for="depthFreq" class="text-left">
                                      start from
                                    </label> <br>
                                    <select name="" id="depthFreq" class="bgDark __selected_welldata fields" v-model="runType" @change="chooseRunType">
                                      <option :value="runType" v-for="(runType, r_id) in runFrom" :key="r_id" >
                                      {{ runType }}
                                      </option>
                                    </select>
                                </div>
                              </section>


                                <div v-if="Object.keys(selected_wellType).length !== 0 || this.srcWellLWDData.well_status == 4 || this.srcWellLWDData.well_status == 104 ">
                                  <p>
                                {{ popUP_message }}
                              </p>
                              <button v-if="popShow_message" class="mt-4 toggleLabel px-2 rounded uppercase text-white" @click="startLWD_WELL">
                                Confirm
                              </button>
                              
                                </div>
                                <!-- <button v-if="wellDetails.sourceType === 'wits0'" class="mt-4 toggleLabel px-2 rounded uppercase text-white" @click="startLWD_WELL">
                                Confirm
                              </button> -->
                            </div>
                          </div>


                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import URLS from "../../config";
import axios from "axios";
import apiService from "../../api/services";
export default {
  emits: [
    "hide_zoom_tooltip",
    "toggle_management",
    "add_well_pop",
    "addwellPopHide",
    "fileTypeToggler",
    "make_it_live"
  ],
  props: {
    onToggleSideBar: Function,
    show_left_bar: Boolean,
    hide_tooltip_zoom: Boolean,
    isBackgroundLive: Boolean,
    displayId: String,
    widgetId: String,
    srcWellData:Object,
    srcWellLWDData:Object,
    wellDetails:Object
  },
  data() {
    return {
      isHamburgerClickedTandD: false,
      selectedType: "WITSML",
      Activate_LWD_POP:false,
      popShow_message:false,
      popUP_message:'',
      mapped_data:[],
      wellData:[],
      selected_wellType:{},
      runFrom:[
        'STARTING',
        'LASTEND'
      ],
      runType:'LASTEND',
      allSourceTypes: ['FLATFILE', 'WITSML', 'WITS0']
      // srcWellLWDData:[]
    };
  },
  computed: {
    darkDark() {
      return this.$store.state.rect.darkmode;
    },
  },
  methods: {
    toggleLeftSideBar() {
      this.onToggleSideBar();
      this.isHamburgerClickedTandD = !this.isHamburgerClickedTandD;
    },
    async stopWellPop(){
      this.selected_wellType ={}
      this.Activate_LWD_POP = !this.Activate_LWD_POP
      const temp_lwd_mn = {
        wellId: this.wellDetails.wellId,
        section: 1,
        feedType: this.wellDetails.sourceType === 'witsml' ? 'WITSML' : this.wellDetails.sourceType,
        solutionType: "LWD LOGS",
        wellBoreName: this.wellDetails.wellBoreName
      }
      try {
        await axios.post(URLS.HOST_URL.DATASERVICES_URL+'lwdmapping/search', temp_lwd_mn).then(res=>{
          console.log('_______res_______', this.srcWellLWDData)
          this.mapped_data = res.data[0]
          if(res.status == 204){
            this.popShow_message = false
            this.popUP_message = 'For this well LWD mapping not available please Complete Mapping to start'
          }else{
            this.popShow_message = true
            this.popUP_message = `Are you sure Do You wanted to ${this.srcWellLWDData.well_status !== 4 ? 'Start' : 'Stop'} LWD for this well ?`
            if(this.wellDetails.sourceType === 'wits0'){
              try {
                // axios.post(URLS.HOST_URL.DATASERVICES_URL+'mnemonicdata/search',{
                //   logType : "LWD",
                //   wellBoreName : this.wellDetails.wellBoreName
                // }).then(response=>{
                //   console.log('___mnemonic_data__response__', response)
                //   this.popShow_message = true
                //   this.wellData = response.data[0]
                  axios.post(URLS.HOST_URL.DATASERVICES_URL+'witsmapping/search',{
                      wellId: this.wellDetails.wellId,
                      wellBoreName: this.wellDetails.wellBoreName,
                      log: "LWD"
                  }).then(mappingWits => {
                    this.wellData = mappingWits.data
                    console.log('_____mappingWits_____before', this.wellData)
                    this.wellData = mappingWits.data.slice().sort((a, b) => a.time - b.time);
                    console.log('_____mappingWits_____after', this.wellData)

                  })
                // })
              } catch (error) {
                console.log('____mnemonic__error____', error)
              }
              // mnemonicdata/search
            }else{
            try {
              axios.post(URLS.HOST_URL.DATASERVICES_URL+'witsml/wellsdata',{
                  entityId: localStorage.getItem('selectedEntityId'),
                  sourceName: this.srcWellLWDData.cloud_src_loc !== undefined ? this.srcWellLWDData.cloud_src_loc : this.wellDetails.cloudSrcLoc,
                  refresh: false,
                  wellid: this.srcWellLWDData.src_well_id !== undefined ? this.srcWellLWDData.src_well_id : this.wellDetails.srcWellId,
                  wellboreid: this.srcWellLWDData.src_well_bore_id !== undefined ? this.srcWellLWDData.src_well_bore_id : this.wellDetails.srcWellBoreId
            }).then((wellData)=>{
              console.log('___wellData__resp___', wellData.data)
              this.wellData = wellData.data.logs['depth']
              // this.Activate_LWD_POP = true
            })
            } catch (error) {
              console.log(error)
            }
          }
          }
        })
      } catch (error) {
        console.log(error)
      }
    console.log('_________srcWellData_________', this.srcWellData)
    console.log('______store___data___', localStorage.getItem('selectedEntityId'))
    // console.log('______store___data___', this.$store.state.data.user)
    },
    selectedWellData(){
      console.log('___selected_welldata___', this.selected_wellType)
      // loginterval
    },
    async startLWD_WELL(){
      console.log('_____well_date_check___1', this.wellDetails.wellStatus)
      console.log('_____well_date_check___2', this.srcWellLWDData)
      // this.srcWellData['trajectory_availability'] = !this.srcWellData['trajectory_availability']

      var arr = []
      var tempDATA = {
        customerName: this.srcWellLWDData.customer_name !== undefined ? this.srcWellLWDData.customer_name : this.wellDetails.customerName,
        longitude: this.srcWellLWDData.longitude !== undefined ? this.srcWellLWDData.longitude : this.wellDetails.longitude,
        latitude: this.srcWellLWDData.latitude !== undefined ? this.srcWellLWDData.latitude : this.wellDetails.latitude,
        log: 'lwd',
        logid: this.wellDetails.sourceType === 'wits0' ? this.wellData[0].frequency : this.srcWellLWDData.logid !== undefined ? this.srcWellLWDData.logid : this.selected_wellType.logid,
        wellId: this.srcWellLWDData.well_id !== undefined ? this.srcWellLWDData.well_id : this.wellDetails.wellId,
        wellName: this.srcWellLWDData.well_name !== undefined ? this.srcWellLWDData.well_name : this.wellDetails.wellName,
        wellBoreName: this.srcWellLWDData.well_borename !== undefined ? this.srcWellLWDData.well_borename : this.wellDetails.wellBoreName,
        rigName: this.srcWellLWDData.rig_name !== undefined ? this.srcWellLWDData.rig_name : this.wellDetails.rigName,
        srcWellId: this.srcWellLWDData.src_well_id !== undefined ? this.srcWellLWDData.src_well_id : this.wellDetails.srcWellId,
        srcWellBoreId: this.srcWellLWDData.src_well_bore_id !== undefined ? this.srcWellLWDData.src_well_bore_id : this.wellDetails.srcWellBoreId,
        sidetrackWellname: this.srcWellLWDData.sidetrack_wellname !== undefined ? this.srcWellLWDData.sidetrack_wellname : this.wellDetails.sidetrackWellname,
        archived: this.srcWellLWDData.archived !== undefined ? this.srcWellLWDData.archived : this.wellDetails.archived,
        wellStatus: this.wellDetails.well_status == 4 ? 5 : 4,
        wellProfile: this.srcWellLWDData.well_profile !== undefined ? this.srcWellLWDData.well_profile : this.wellDetails.wellProfile,
        drillingType: this.srcWellLWDData.drilling_type !== undefined ? this.srcWellLWDData.drilling_type : this.wellDetails.drillingType,
        pdfWellname: this.srcWellLWDData.pdf_wellname !== undefined ? this.srcWellLWDData.pdf_wellname : this.wellDetails.pdfWellname,
        multiCustomer: this.srcWellLWDData.multi_customers !== undefined ? this.srcWellLWDData.multi_customers : this.wellDetails.multiCustomer,
        cloudSrcLoc: this.srcWellLWDData.cloud_src_loc !== undefined ? this.srcWellLWDData.cloud_src_loc : this.wellDetails.cloudSrcLoc,
        sourceType: this.srcWellLWDData.source_type !== undefined ? this.srcWellLWDData.source_type : this.wellDetails.sourceType,
        feedType: this.srcWellLWDData.feed_type !== undefined ? this.srcWellLWDData.feed_type : this.wellDetails.feedType,
        time: this.srcWellLWDData.time !== undefined ? this.srcWellLWDData.time : this.wellDetails.time,
        subSurfLong: this.srcWellLWDData.sub_surf_long !== undefined ? this.srcWellLWDData.sub_surf_long : this.wellDetails.subSurfLong,
        subSurfLat: this.srcWellLWDData.sub_surf_lat !== undefined ? this.srcWellLWDData.sub_surf_lat : this.wellDetails.subSurfLat,
        startTime: this.srcWellLWDData.start_time !== undefined ? this.srcWellLWDData.start_time : this.wellDetails.startTime,
        endTime: this.srcWellLWDData.end_time !== undefined ? this.srcWellLWDData.end_time : this.wellDetails.endTime,
        startDepth: this.wellDetails.sourceType === 'wits0' ? 0 : this.srcWellLWDData.start_depth !== undefined ? this.srcWellLWDData.start_depth : this.selected_wellType.startindex,
        endDepth: this.srcWellLWDData.end_depth !== undefined ? this.srcWellLWDData.end_depth : this.selected_wellType.endindex,
        //this.srcWellLWDData.end_depth ? this.srcWellLWDData.end_depth : this.mapped_data.endDepth,
        laststatusupdate: this.srcWellLWDData.laststatusupdate ? this.srcWellLWDData.laststatusupdate : this.wellDetails.laststatusupdate,
        topicname: this.srcWellLWDData.topicname !== undefined ? this.srcWellLWDData.topicname : null,
        tndtopicname: this.srcWellLWDData.tnd_topicname !== undefined ? this.srcWellLWDData.tnd_topicname : null,
        timeRemarksAvialbility: this.srcWellLWDData.time_remarks_availability !== undefined ? this.srcWellLWDData.time_remarks_availability : false,
        trajectoryAvailability: this.srcWellLWDData.trajectory_availability !== undefined ? this.srcWellLWDData.trajectory_availability : false,
        logInterval: this.wellDetails.sourceType === 'wits0' ? this.wellData[0].frequency : this.srcWellLWDData.log_interval !== undefined ? this.srcWellLWDData.log_interval : this.selected_wellType.loginterval,
        swabSurgeTopicname: this.srcWellLWDData.swab_surge_topicname !== undefined ? this.srcWellLWDData.swab_surge_topicname : null,
        message: this.srcWellLWDData.message !== undefined ? this.srcWellLWDData.message : null,
        draftDepth: this.srcWellLWDData.draft_depth !== undefined ? this.srcWellLWDData.draft_depth : null,
        draftTime: this.srcWellLWDData.draft_time !== undefined ? this.srcWellLWDData.draft_time : null,
        wellEndDepth: this.srcWellLWDData.well_end_depth !== undefined ? this.srcWellLWDData.well_end_depth : this.selected_wellType.endindex,
        wellEndTime: this.srcWellLWDData.well_end_time !== undefined ? this.srcWellLWDData.well_end_time : null,
        rigFloorTopicName: this.srcWellLWDData.rigfloor_topicname !== undefined ? this.srcWellLWDData.rigfloor_topicname : null

      }
      arr.push(tempDATA)
      var startlogInfo = {
        wellid: this.srcWellLWDData.well_id !== undefined ? this.srcWellLWDData.well_id : this.wellDetails.wellId,
        wellborename: this.srcWellLWDData.well_borename !== undefined ? this.srcWellLWDData.well_borename : this.wellDetails.wellBoreName,
        srcWellId: this.srcWellLWDData.src_well_id !== undefined ? this.srcWellLWDData.src_well_id : this.wellDetails.srcWellId,
        srcWellBoreId: this.srcWellLWDData.src_well_bore_id !== undefined ? this.srcWellLWDData.src_well_bore_id : this.wellDetails.srcWellBoreId,
        sourcelocation: this.srcWellLWDData.cloud_src_loc !== undefined ? this.srcWellLWDData.cloud_src_loc : this.wellDetails.cloudSrcLoc,
        entityId: localStorage.getItem('selectedEntityId'),
        wellType: this.srcWellLWDData.feed_type !== undefined ? this.srcWellLWDData.feed_type : this.wellDetails.feedType,
        log: "lwd",
        logId: this.wellDetails.sourceType === 'wits0' ? this.wellData[0].frequency : this.srcWellLWDData.logid !== undefined ? this.srcWellLWDData.logid : this.selected_wellType.logid,
        restart: false,
        topicName: this.wellDetails.sourceType === 'wits0' ? this.wellData[0].topicName : ''
    }
      var reStartlogInfo = {
        wellid: this.srcWellLWDData.well_id !== undefined ? this.srcWellLWDData.well_id : this.wellDetails.wellId,
        wellborename: this.srcWellLWDData.well_borename !== undefined ? this.srcWellLWDData.well_borename : this.wellDetails.wellBoreName,
        srcWellId: this.srcWellLWDData.src_well_id !== undefined ? this.srcWellLWDData.src_well_id : this.wellDetails.srcWellId,
        srcWellBoreId: this.srcWellLWDData.src_well_bore_id !== undefined ? this.srcWellLWDData.src_well_bore_id : this.wellDetails.srcWellBoreId,
        sourcelocation: this.srcWellLWDData.cloud_src_loc !== undefined ? this.srcWellLWDData.cloud_src_loc : this.wellDetails.cloudSrcLoc,
        entityId: localStorage.getItem('selectedEntityId'),
        wellType: this.srcWellLWDData.feed_type !== undefined ? this.srcWellLWDData.feed_type : this.wellDetails.feedType,
        log: "lwd",
        logId: this.wellDetails.sourceType === 'wits0' ? this.wellData[0].frequency : this.srcWellLWDData.logid !== undefined ? this.srcWellLWDData.logid :  this.selected_wellType.logid,
        restart: this.srcWellLWDData !== undefined ? true : false,
        restartType: this.runType,
        topicName: this.wellDetails.sourceType === 'wits0' ? this.wellData[0].topicName : ''
    }
    // if(this.wellDetails.sourceType === 'wits0'){
    //   const topicname = this.wellData[0].topicname
    //   startlogInfo.topicname(topicname)
    // }
    // if (this.wellDetails.sourceType === 'wits0') {
    //   console.log('_______wellData_____', this.wellData[0])
    //   const topicName = this.wellData[0].topicName;
    //   startlogInfo.topicName.push(topicName);
    //   console.log('_______wellData_____', startlogInfo.topicName)

    // }

    var logStop = {
          wellid: this.srcWellLWDData.well_id ? this.srcWellLWDData.well_id :this.wellDetails.wellId,
          wellborename: this.srcWellLWDData.well_borename ? this.srcWellLWDData.well_borename : this.wellDetails.wellBoreName,
          log: "lwd",
          customer: this.srcWellLWDData.customer_name ? this.srcWellLWDData.customer_name : this.wellDetails.customerName,
          logId: this.srcWellLWDData.logid ? this.srcWellLWDData.logid : this.selected_wellType.logid  
    }
    console.log('___this.srcWellLWDData.length___', this.srcWellLWDData.length)
    try {
      // await axios.post(URLS.HOST_URL.DATASERVICES_URL + "sourcelog/list/saveorupdate", { list: arr }).then((resp)=>{
      //   console.log('__respppp____', resp)
      if (this.srcWellLWDData.length === 0 || this.srcWellLWDData.length === undefined || this.srcWellLWDData.length == null) {
          await axios.post(URLS.HOST_URL.DATASERVICES_URL + "sourcelog/list/saveorupdate", { list: arr }).then((resp) => {
            console.log('__respppp____', resp);
            this.$emit('make_it_live');
          });
        }
        setTimeout(()=> {this.$emit('make_it_live')},5000);
        if((this.srcWellLWDData.well_status !== 4 && this.wellDetails.sourceType === 'witsml')){
        axios.post(URLS.HOST_URL.DATASERVICES_URL + "witsml/logs", this.wellDetails.wellStatus == 3 || this.srcWellLWDData.well_status == undefined ? startlogInfo : reStartlogInfo).then((logs)=>{
          if(logs){
          this.$toast.success('LWD log Updated')
        }
        })
      }else if((this.srcWellLWDData.well_status !== 4  && this.wellDetails.sourceType === 'wits0')){
        axios.post(URLS.HOST_URL.DATASERVICES_URL + "wits/logs", this.wellDetails.wellStatus == 3 || this.srcWellLWDData.well_status == undefined ? startlogInfo : reStartlogInfo).then((logs)=>{
          if(logs){
          this.$toast.success('LWD log Updated')
        }
        })
      }
      else{
        axios.post(URLS.HOST_URL.DATASERVICES_URL + "logs/stop", logStop).then((logs)=>{
          if(logs){
            this.$emit('make_it_live')
          this.$toast.success('LWD log stopped')
        }
        })
      }
      setTimeout(()=> {this.$emit('make_it_live')},0)
    } catch (error) {
      console.log(error)
    }
      this.Activate_LWD_POP = false
      // alert('lwd__well_start')
      console.log('______store___data___', this.$store.state.data)
    },
  },
  mounted() {
    // this.selectedType = true;
    this.srcWellData
    console.log('_____wellDetails____', this.wellDetails)
    console.log('_____srcWellLWDData____', this.srcWellLWDData)
  },
  watch: {
    selectedType(val) {
      this.$emit("fileTypeToggler", val);
    },
  },
};
</script>
<style scoped>
.hamburgerTandD path {
    /* fill: var(--defalutHamburger); */
    fill: var(--textColor);
}

.ActiveHamburgerTandD path {
    /* fill: var(--activeHamburger); */
    fill:var(--activeTextColor);
}
.buttonStyles {
  border: 1px solid var(--activeTextColor);
  /* font-size: 10px; */
  color: var(--activeTextColor);
  border-radius: 3px;
  padding: 1px 5px;
  text-transform: uppercase;
}
.fontSize {
  font-size: calc(0.2vw + 0.2vh + 0.65vmin);
}
.toggleLabel {
  background: var(--activeTextColor);
}
.toggleLabel .ball {
  background-color: var(--sidebarbg);
}
.liveBtnDot {
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 50%;
}
.newStylesLiveBtn {
  display: flex;
  align-items: center;
  gap: 7px;
  border-radius: 10px;
  padding: 2px 8px;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 9px;
  color: #fff;
}
.startButton{
  background: var(--liveBtnGreen);
}
.tbl_mdl_lwd_model{
  width: 50%;
  height: 50%;
}
.__selected_welldata{
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: var(--root_nav);
  border-radius: 5px;
  padding: 10px;
  border: unset;
  color: var(--textColor);
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.15);
}
.startStopSection{
  display: flex;
    align-items: center;
    height: 100%;
}
.fields{
  width: 100%;
  margin-top: 4px;
  color: var(--sidebarListColor);
  background-color: var(--sidebarListBgColor);
  /* font-size: 12px !important; */
  line-height: 1.4;
}
.newSty_bg {
  background: var(--central_bg) !important;
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.15) !important;
}
</style>
